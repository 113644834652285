// // export default CustomTerminal;
// import React, { useEffect, useState } from 'react';
// import { Terminal } from 'react-terminal';
// import io from 'socket.io-client';

// const SOCKET_SERVER_URL = 'http://your-server-url'; // Replace with your server URL

// const TerminalComponent = () => {
//   const [socket, setSocket] = useState(null);
//   const [terminalOutput, setTerminalOutput] = useState([]);
//   const [id, setId] = useState(null);

//   useEffect(() => {
//     // Fetch data to get ID
//     const fetchId = async () => {
//       try {
//         const response = await fetch('http://your-api-url/get-id'); // Replace with your API URL
//         const data = await response.json();
//         setId(data.id); // Assuming the API response has an 'id' field
//       } catch (error) {
//         console.error('Error fetching ID:', error);
//       }
//     };

//     fetchId();
//   }, []);

//   useEffect(() => {
//     if (id !== null) {
//       // Create socket connection with the fetched ID
//       const socketConnection = io(`${SOCKET_SERVER_URL}?userId=${id}`, {
//         extraHeaders: {
//           'Custom-Header': 'your-custom-header-value', // Set your custom header here
//         }
//       });

//       setSocket(socketConnection);

//       // Listen for messages from the server
//       socketConnection.on('message', (data) => {
//         setTerminalOutput((prevOutput) => [...prevOutput, data]);
//       });

//       // Cleanup on component unmount
//       return () => {
//         socketConnection.disconnect();
//       };
//     }
//   }, [id]);

//   const handleCommand = (command) => {
//     if (socket) {
//       socket.emit('command', command);
//     }
//     setTerminalOutput((prevOutput) => [...prevOutput, `> ${command}`]);
//   };

//   return (
//     <div>
//       <h1>Socket.IO React-Terminal Example</h1>
//       <Terminal
//         color="green"
//         backgroundColor="black"
//         barColor="black"
//         style={{ fontWeight: "bold", fontSize: "1em" }}
//         commands={{
//           echo: handleCommand,
//           // Add more commands if needed
//         }}
//         descriptions={{
//           echo: 'Echoes the input',
//           // Add descriptions for more commands
//         }}
//         msg="Type a command"
//         commandPassThrough={handleCommand}
//       />
//       <div style={{ marginTop: '10px' }}>
//         {terminalOutput.map((output, index) => (
//           <div key={index}>{output}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TerminalComponent;
import React, { useEffect, useState } from 'react';
import { ReactTerminal } from 'react-terminal';
import { io } from 'socket.io-client';

const SOCKET_SERVER_URL = 'wss://docker.sadeem.cloud'; // Change to your WebSocket URL

const TerminalComponent = () => {
  const [socket, setSocket] = useState(null);
  const [terminalOutput, setTerminalOutput] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const MAX_RETRIES = 5;

  useEffect(() => {
    const connectSocket = () => {
      console.log('Attempting to connect to WebSocket server...');

      const socketConnection = io(SOCKET_SERVER_URL, {
        path: '/api/websocket/exec',
        query: {
          endpointId: '2',
          id: '09d56eb7f7ed62f76a85554107d145053d58b90130846994263d5ec8803fe2af',
        },
        extraHeaders: {
          'x-api-key': 'ptr_13Du1HxAXmjg80uTaG151R7G2RrKYFsnjWUDV+/TYW0=',
          // Connection: 'Upgrade',
          // Upgrade: 'websocket',
        },
        // transports: ['websocket'], // Force WebSocket transport
        // secure: true,
        // rejectUnauthorized: true, // Use this option for self-signed certificates
        // Sec-WebSocket-Key: "POMLCOiRaBwVPguiGgfImw==",
      });

      setSocket(socketConnection);

      // Listen for connection event
      socketConnection.on('connect', () => {
        console.log('Connected to WebSocket server');
        // setRetryCount(0); // Reset retry count on successful connection
      });

      // Listen for messages from the server
      socketConnection.on('message', (data) => {
        console.log('Message received:', data); // Log received message
        setTerminalOutput((prevOutput) => [...prevOutput, `Received: ${data}`]);
      });

      // Listen for disconnection event
      socketConnection.on('disconnect', (reason) => {
        console.log('Disconnected from WebSocket server:', reason);
      });

      // Listen for connection errors
      socketConnection.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });

      // Listen for general errors
      socketConnection.on('error', (error) => {
        console.error('WebSocket error:', error);
      });

      // Listen for reconnecting event
      socketConnection.on('reconnecting', (attempt) => {
        console.log(`Reconnecting attempt ${attempt}`);
      });

      // Listen for reconnection error
      socketConnection.on('reconnect_error', (error) => {
        console.error('Reconnection error:', error);
      });
    };

    connectSocket();

    // Cleanup on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const handleCommand = (command) => {
    if (socket) {
      socket.emit('command', command);
      setTerminalOutput((prevOutput) => [...prevOutput, `Sent: ${command}`]);
    }
  };

  return (
    <div>
      <h1>Socket.IO React-Terminal Example</h1>
      <ReactTerminal
        prompt=">"
        color="green"
        backgroundColor="black"
        barColor="black"
        style={{ fontWeight: 'bold', fontSize: '1em' }}
        commands={{
          '*': handleCommand,
        }}
        descriptions={{
          '*': 'Handles any command',
        }}
        welcomeMessage="Welcome to the custom terminal! Type a command."
      />
      <div
        style={{
          marginTop: '10px',
          color: 'white',
          backgroundColor: 'black',
          padding: '10px',
        }}
      >
        {terminalOutput.map((output, index) => (
          <div key={index}>{output}</div>
        ))}
      </div>
    </div>
  );
};

export default TerminalComponent;
