import './App.css';
import TerminalComponent from './pages/TerminalComponent';
function App() {
  return (
    <div style={{ height: '100vh', backgroundColor: 'black' }}>
      <TerminalComponent />
    </div>
  );
}

export default App;
